import { request, noTimeOutReq } from '@/utils/request.js'

//根据root配置文件信息查询考试信息
export function getCallOrgExamPlaceApi () {
  return request({
    url: `/call/batch/list/orgExamInfo`,
    method: 'post',
  })
}

//根据考点id查询考点考试列表
export function getCallOrgExamInfoApi (sysOrgExamPlaceId) {
  return request({
    url: `/call/batch/list/exam/${sysOrgExamPlaceId}`,
    method: 'post',
  })
}

