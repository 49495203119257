<!-- 点名报到页 -->
<template>
  <div id="herder">
    <el-button
      v-throttle
      @click="quitBtn"
      size="medium"
      icon="el-icon-back"
      class="returnBtn"
      round
      >返回</el-button
    >
    <el-card shadow="always">
      <div id="county-content">
        <span>区县</span>
        <el-select
          v-model="returnParam.areaId"
          placeholder="请选择区县"
          @change="chooseExamCounty"
        >
          <el-option
            v-for="(examCounty, index) in examCountyList"
            :key="index"
            :label="examCounty.sysOrgAreName"
            :value="examCounty.sysOrgAreId"
          ></el-option>
        </el-select>
      </div>
      <div id="place-content">
        <span>考点</span>
        <el-select
          v-model="returnParam.examPlaceId"
          placeholder="请选择考点"
          @change="chooseExamPlace"
        >
          <el-option
            v-for="(examPlace, index) in examPlaceList"
            :key="index"
            :label="examPlace.sysOrgExamPlaceName"
            :value="examPlace.sysOrgExamPlaceId"
          ></el-option>
        </el-select>
      </div>
      <div class="box-content">
        <span>选择考试</span>
        <el-select
          v-model="returnParam.examId"
          placeholder="请选择考试"
          @change="chooseExamInfo"
        >
          <el-option
            v-for="(examInfo, index) in examInfoList"
            :key="index"
            :label="examInfo.examName"
            :value="examInfo.examId"
          ></el-option>
        </el-select>
      </div>
      <div class="time-content">
        <span>考试时间</span>
        <span v-if="examInfo.examStartTime != 0">{{
          formatDateTime(
            new Date(examInfo.examStartTime),
            'yyyy-MM-dd hh:mm:ss'
          )
        }}</span>
        <span v-if="examInfo.examStartTime == 0">请选择考试</span>
      </div>
      <div id="report_btn">
        <el-button
          v-throttle
          type="primary"
          size="medium"
          icon="el-icon-edit-outline"
          @click="startToReport"
          :disabled="
            !returnParam.areaId ||
            !returnParam.examPlaceId ||
            !returnParam.examId
          "
          >开始报到</el-button
        >
      </div>
      <div id="footer-content">
        <div id="footer-content-img"></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  getCallOrgExamPlaceApi,
  getCallOrgExamInfoApi,
} from '@/api/rollCall/report.js'
import { isEmpty } from '@/utils/util.js'
// import cryptoAES from '@/utils/crypto.js'
import { quit } from '@/api/auth.js'
import { removeToken, removeName, getLoginRoad } from '@/utils/token.js'
import { removeStore } from '@/utils/token.js'
export default {
  name: 'report',
  data() {
    return {
      returnParam: {
        areaId: '',
        examPlaceId: '',
        examId: '',
      },
      examCountyList: [],
      examPlaceList: [],
      examInfoList: [],
      examInfo: {
        examCode: '',
        examId: 0,
        examName: '',
        examStartTime: 0,
        randomTimingType: 0,
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //根据root配置文件信息查询考试信息
      this.getCallOrgExamPlace()
    },
    //根据root配置文件信息查询考试信息
    getCallOrgExamPlace() {
      getCallOrgExamPlaceApi().then((res) => {
        if (res.success) {
          this.examCountyList = []
          this.examCountyList = res.data
        } else {
        }
      })
    },
    //选择区县
    chooseExamCounty(areaId) {
      this.examPlaceList = []
      this.returnParam.examPlaceId = ''
      this.examInfoList = []
      this.returnParam.examId = ''
      this.examInfo.examStartTime = 0
      this.examCountyList.forEach((item) => {
        if (item.sysOrgAreId == areaId) {
          this.examPlaceList = item.sysOrgExamPlaceList
        }
      })
    },
    //选择考点
    chooseExamPlace() {
      this.examInfoList = []
      this.returnParam.examId = ''
      this.examInfo.examStartTime = 0
      //根据考点id查询考点考试列表
      if (isEmpty(this.returnParam.examPlaceId)) {
        return
      }
      this.getCallOrgExamInfo()
    },
    //根据考点id查询考点考试列表
    getCallOrgExamInfo() {
      getCallOrgExamInfoApi(this.returnParam.examPlaceId).then((res) => {
        if (res.success) {
          this.examInfoList = []
          this.examInfoList = res.data
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //选择考试
    chooseExamInfo(examId) {
      this.examInfo = {}
      this.examInfoList.forEach((item) => {
        if (item.examId == examId) {
          this.examInfo = item
        }
      })
    },
    startToReport() {
      //进入点名
      this.enterCall()
    },
    //进入点名
    enterCall() {
      let rotueUrl = null
      let _examId = this.returnParam.examId
      let _examPlaceId = this.returnParam.examPlaceId
      let _randomTimingType = this.examInfo.randomTimingType
      let _randomTimingDrawType = this.examInfo.randomTimingDrawType
      switch (this.examInfo.randomTimingType) {
        // TODO 分开点名
        case 1:
          rotueUrl = this.$router.resolve({
            path: '/rollCall/separateRollCall',
            query: {
              examId: _examId,
              examPlaceId: _examPlaceId,
              randomTimingType: _randomTimingType,
              randomTimingDrawType: _randomTimingDrawType,
            },
          })
          window.open(rotueUrl.href, '_self')
          break
        case 2:
          rotueUrl = this.$router.resolve({
            path: '/rollCall/centralizeRollCall',
            query: {
              examId: _examId,
              examPlaceId: _examPlaceId,
              randomTimingType: _randomTimingType,
              randomTimingDrawType: _randomTimingDrawType,
            },
          })
          window.open(rotueUrl.href, '_self')
          break
      }
    },
    /* 时间格式化 */
    formatDateTime(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    // 退出登录
    quitBtn() {
      quit().then((res) => {
        if (res.success) {
          removeStore('LOCAL_OSS')
          removeStore('LOCAL_MEDIA')
          removeToken()
          removeName()
          setTimeout(() => {
            // if (!getLoginRoad()) {
            // removeLoginRoad()
            // this.$router.replace('/selectLogin')
            this.$router.replace('/checkInLogin')
            // } else {
            // removeLoginRoad()
            // this.$router.replace('/cityPlatformLogin')
            // }
          }, 500)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#herder {
  padding: 0 40px;
  margin-top: 60px;
  font-family: 'Source Han Sans CN-Medium, Source Han Sans CN';
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-card__body {
  margin-top: 40px;
}
::v-deep .el-select {
  width: 429px;
}

#county-content,
#place-content,
.box-content,
.time-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

#county-content > span:first-child,
#place-content > span:first-child,
.box-content > span:first-child,
.time-content > span:first-child {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  display: inline-block;
  width: 72px;
  text-align: right;
}

.time-content > span:last-child {
  display: inline-block;
  width: 429px;
  text-align: left;
  font-weight: bold;
}

#report_btn {
  margin-top: 20px;
  text-align: center;
}

#footer-content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#footer-content-img {
  background: url('./image/report.png') no-repeat;
  width: 1107px;
  height: 408px;
}
.returnBtn {
  position: fixed;
  top: 20px;
  left: 20px;
}
</style>
